<template>
  <wrapper-with-close-btn :preventNavigation="true" class="job-edit" @back="goBack">
    <component :is="shownComponent" />
  </wrapper-with-close-btn>
</template>

<script>
import {mapMutations} from 'vuex';
import EditLocation from '@/components/job/edit/JobEditLocation';
import EditDate from '@/components/job/edit/JobEditDate';

export default {
  components: {
    EditLocation,
    EditDate,
  },
  computed: {
    shownComponent() {
      switch (this.$route.query.step) {
        case 'location':
          return 'EditLocation';
        case 'date':
          return 'EditDate';
      }
      return '';
    },
  },
  methods: {
    ...mapMutations('jobInfo', ['CLEAR_STORE']),
    goBack() {
      const {params, query} = this.$route;
      this.CLEAR_STORE();
      this.$router.push({
        name: 'ShowJob',
        params,
        query,
      });
    },
  },
  created() {
    const {params, query} = this.$route;
    if (!query.step) {
      this.$router.replace({
        params,
        query: {...query, step: 'location'},
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.job-edit {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 30px;

  @include mf($gridMDBreakpoint) {
    margin-top: 60px;
  }
}
</style>
