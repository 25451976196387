<template>
  <div class="find-location" :class="{'find-location--loading': isLoading}">
    <Loader v-if="isLoading" class="find-location__loader" />
    <location-form
      v-else
      :radios="radios"
      :chosen-location="chosenLocation"
      :zip="jobInfo.address.zipCode"
      title="Please select another nearby location:"
      @change="SET_CHOSEN_LOCATION"
    >
      <StepNavigationButtons
        @back="goBack"
        @confirm="goToDateStep"
        :disabled="!chosenLocation"
        confirm-button-text="Continue"
        back-button-text="Cancel"
      />
    </location-form>
  </div>
</template>

<script>
import {mapMutations, mapActions, mapGetters} from 'vuex';
import FindLocation from '@/components/claim/components/carry-in-repair/CarryInRepairFindLocation';
import Loader from '@/components/shared/Loader';
import LocationForm from '@/components/shared/ClaimLocationForm';
import StepNavigationButtons from '@/components/shared/StepNavigationButtons';

export default {
  extends: FindLocation,
  components: {
    Loader,
    LocationForm,
    StepNavigationButtons,
  },
  data: () => ({
    isLoading: true,
  }),
  computed: {
    ...mapGetters('jobInfo', ['getByServiceJobId', 'jobInfo']),
    claimId() {
      return this.$route.params.id;
    },
  },
  methods: {
    ...mapActions('user', ['DECRYPT_PARAMS']),
    ...mapActions('jobInfo', ['GET_CLAIMS_LIST']),
    ...mapMutations('jobInfo', ['SET_JOB_INFO']),
    ...mapActions('deviceInformation', ['GET_DEVICE_INFO']),
    ...mapActions('requestService', ['GET_AVAILABILITY']),
    ...mapMutations('requestService/carryInRepair', ['CLEAR_STORE']),
    goBack() {
      const {params, query} = this.$route;
      this.CLEAR_STORE();
      this.$router.push({
        name: 'ShowJob',
        params,
        query,
      });
    },
    async goToDateStep() {
      const {query} = this.$route;
      this.$router.push({
        name: 'EditJob',
        params: {id: this.claimId},
        query: {...query, step: 'date'},
      });
    },
  },
  watch: {
    claimId: {
      async handler(claimId) {
        this.isLoading = true;
        this.CLEAR_STORE();

        const {customerRef} = await this.DECRYPT_PARAMS({
          customerRef: this.$route.query.customerRef,
        });

        await this.GET_CLAIMS_LIST({
          customerRef,
          serviceJobID: claimId,
        });

        this.SET_JOB_INFO(this.getByServiceJobId(claimId));

        const {jobInfo} = this;

        await this.GET_DEVICE_INFO({
          customerRef,
          id: jobInfo.claimDetails.inventoryEquipmentID,
        });

        const issueCodes = [jobInfo.complaintCode];

        const addressPayload = {
          ...jobInfo.address,
        };
        delete addressPayload.fullAddress;

        await this.GET_AVAILABILITY({
          mode: 'Repair',
          params: {
            ...addressPayload,
            issueCodes,
          },
        });

        this.isLoading = false;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.find-location {
  padding-bottom: 200px;
  &--loading {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  @include mf($gridMDBreakpoint) {
    padding-bottom: 0;
    &--loading {
      padding-bottom: 80px;
    }
  }
}
</style>
